(function (){
    tests();
    testsV2();
    testsDir();
    testsCalc();
    testsCats();

    $('.showMenu').on('click',function (e){
        let sct = $('html, body').scrollTop();
        $('html, body').css('overflow', 'hidden');
        $('html, body').scrollTop(sct);
        $('.mobile-menu').fadeIn(300, function (){
            $('.showMenu').hide();
            $('.hideMenu').show();
        })
    })
    $('.hideMenu').on('click',function (e){
        $('.mobile-menu').fadeOut(300, function (){
            $('html, body').css('overflow', '');
            $('.showMenu').show();
            $('.hideMenu').hide();
        })
    })



    $(".structure__list__itm__visible").click(function(){
        console.log('click', $(this).parent().hasClass('structure__list__itm--active'));
        if($(this).parent().hasClass('structure__list__itm--active')){
            $(this).parent().removeClass('structure__list__itm--active')
        } else {
            $(this).parent().addClass('structure__list__itm--active')
        }
    });

    $(".tabs__wrapper .tab, .tabs__wrapper .tab_js").click(function() {
        $(this).closest('.tabs__wrapper').find(".tab, .tab_js").removeClass("active").eq($(this).index()).addClass("active");
        $(".tab_item").hide().eq($(this).index()).fadeIn()
    });/*.eq(0).addClass("active");*/

    $(".tests-faq .q").click(function(){
        if($(this).hasClass("active")) {
            $(this).next().fadeOut();
        }
        else {
            $(this).next().fadeIn();
        }
        $(this).toggleClass("active");
    });

    // Question handler
    $('li.q').on("click", function() {
        $(this).toggleClass("q--active");
        // Get next element
        $(this).next()
            .slideToggle("500")
            // Select all other answers
            .siblings('li.a')
            .slideUp();
    });

    $('.section-slider').owlCarousel({
        stagePadding: 50,
        loop: true,
        margin: 30,
        autoWidth: true,
        nav: false,
        dots: false
    });
    $('.read-more-slider').owlCarousel({
        items: 1,
        stagePadding: 0,
        loop: true,
        margin: 30,
        nav: false,
        dots: false,
        responsive: {
            768: {
                items: 3,
            },
            400: {
                items: 2
            }
        }
    });

    $('.s-video-block__slider').owlCarousel({
        items: 1,
        stagePadding: 0,
        loop: true,
        margin: 30,
        nav: false,
        dots: false,
        responsive: {
            768: {
                items: 1,
            },
            400: {
                items: 1
            }
        }
    });
    var sliderVideo = $('.s-video-block__slider');
    $('.s-video-block__nav-next').click(function () {
        sliderVideo.trigger('next.owl.carousel');
    });
    $('.s-video-block__nav-prev').click(function () {
        sliderVideo.trigger('prev.owl.carousel');
    });

    var sliderMore = $('.read-more-slider');
    $('.read-more__nav-next').click(function () {
        sliderMore.trigger('next.owl.carousel');
    });
    $('.read-more__nav-prev').click(function () {
        sliderMore.trigger('prev.owl.carousel');
    });

    $(document).on('click', '.load-more', function(){
        console.log('click')
        var targetContainer = $('.load-more-container'),          //  Контейнер, в котором хранятся элементы
            url =  $('.load-more').attr('data-url');    //  URL, из которого будем брать элементы

        console.log(targetContainer)
        console.log(url)
        $('.load-more').remove();
        $('.load-more-wrapper').append('<div class="loader"></div>');

        if (url !== undefined) {
            $.ajax({
                type: 'GET',
                url: url,
                dataType: 'html',
                success: function(data){

                    //  Удаляем старую навигацию
                    $('.load-more-wrapper').remove();
                    var elements = $(data).find('.load-more-item'),  //  Ищем элементы
                        pagination = $(data).find('.load-more-wrapper');//  Ищем навигацию

                    targetContainer.append(elements);   //  Добавляем посты в конец контейнера
                    targetContainer.append(pagination); //  добавляем навигацию следом

                    console.log(elements)
                    console.log(pagination)
                }
            })
        }

    });
})()